<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            type="year"
            default="today"
            label="LBLYEAR"
            name="year"
            v-model="searchParam.year"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="grid"
      title="부서별(월별) 실시율 통계"
      :columns="grid.columns"
      :data="grid.data"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "safety-check-status",
  props: {
  },
  data() {
    return {
      grid: {
        columns: [
          {
            fix: true,
            name: "plantName",
            field: "plantName",
            style: 'width:100px',
            label: "LBLPLANT",
            align: "center",
            sortable: false,
          },
          {
            fix: true,
            name: "deptName",
            field: "deptName",
            style: 'width:180px',
            label: "LBLDEPT",
            align: "center",
            sortable: false,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        year: '',
      },
      editable: true,
      listUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.safetycheck.status.list.url;
      this.setHeader();
    },
    setHeader() {
      for(let i = 1; i <= 12; i++) {
        this.grid.columns.push({
          label: i + '월',
          align: 'center',
          child: [
            {
              name: 'mon' + i,
              field: 'mon' + i,
              label: '주',
              align: 'center',
              style: 'width:40px',
              sortable: true,
            },
            {
              name: 'nmon' + i,
              field: 'nmon' + i,
              label: '야',
              align: 'center',
              style: 'width:40px',
              sortable: true,
            },
            {
              name: 'monc' + i,
              field: 'monc' + i,
              label: '실시율-주(%)',
              align: 'center',
              style: 'width:70px',
              sortable: true,
            },
            {
              name: 'nmonc' + i,
              field: 'nmonc' + i,
              label: '실시율-야(%)',
              align: 'center',
              style: 'width:70px',
              sortable: true,
            },
          ]
        })
      }
      this.getList();
    },
    getList() {
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
  },
};
</script>